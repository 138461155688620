
import VueTypes from 'vue-types';

import campaignOverview from '@/gql/queries/pages/campaignOverview.gql';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
  },
  data() {
    return {
      entries: null,
    };
  },
  async fetch() {
    const { entries } = await this.$cms.query({
      query: campaignOverview,
      variables: {
        site: this.$site,
      },
    });
    this.entries = entries;
  },
};
