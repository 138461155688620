import { render, staticRenderFns } from "./CampaignStories.vue?vue&type=template&id=4a629d8c&scoped=true"
import script from "./CampaignStories.vue?vue&type=script&lang=js"
export * from "./CampaignStories.vue?vue&type=script&lang=js"
import style0 from "./CampaignStories.vue?vue&type=style&index=0&id=4a629d8c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a629d8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Container: require('/workspace/app/components/layout/Container.vue').default,CampaignStoryCard: require('/workspace/app/components/cards/CampaignStoryCard.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
