import { render, staticRenderFns } from "./CampaignStoryCard.vue?vue&type=template&id=4d13baa8&scoped=true"
import script from "./CampaignStoryCard.vue?vue&type=script&lang=js"
export * from "./CampaignStoryCard.vue?vue&type=script&lang=js"
import style0 from "./CampaignStoryCard.vue?vue&type=style&index=0&id=4d13baa8&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d13baa8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Visual: require('/workspace/app/components/media/Visual.vue').default,Icon: require('/workspace/app/components/icons/Icon.vue').default})
